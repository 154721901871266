@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Sora', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
*{
    margin: 0;
    padding: 0;
}
a, Link{
    color: black !important
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page {
    /* width: 80%; */
}

.fa-bars,
.sidebar {
    display: none !important;
}

.container {
    padding: 0 2% !important;
    min-width: 100% !important;
    max-width: 100% !important;
}

@media only screen and (max-width: 1536px) {
    .page {
        width: 100%;
        justify-content: center;
    }
    .homesearch {
        width: 80%;
    }
}

@media only screen and (max-widtH: 1028px) {
    
    .nominee .sideone,
    .nominee form {
        width: 100% !important;
        padding: 0.5rem 0.2rem !important
    }
}

@media only screen and (max-width: 1014px){
    
    .nominee .cardNominee{
        width: 100% !important;
        margin: auto !important;
    }
    .nominee .cardImg{
        height: 300px !important;
    }
}

@media only screen and (max-width: 828px){
    .nominee {
        flex-direction: column !important;
        align-items: center !important;
    }
    .nominee .sideone{
        width: 70% !important;
    }
    .nominee form{
        width: 70% !important;
    }
    .nominee .InputField{
        flex-direction: column !important;
    }
    .nominee .InputField div{
        width: 100% !important
    }
}
@media only screen and (max-width: 600px){
    .nominee .sideone{
        width: 90% !important;
        padding: 0 !important;
        margin: 0 !important;
    }
    .nominee form{
        width: 90% !important;
    }
}

@media only screen and (max-width: 768px) {
    .footer {
        flex-direction: column !important;
    }
    .footer section {
        width: 100% !important;
        margin-bottom: 1rem !important;
    }
    .nominee .cardNominee{
        width: 95% !important;
        margin: auto !important;
    }
    .nominee .cardImg{
        height: 200px !important;
    }
    .nominee .sideone{
        width: 100% !important;
    }
    .nominee form{
        width: 100% !important;
    }
}

@media only screen and (max-width: 640px) {
    .header{
        padding: 0 10%;
    }
    .navList {
        display: none !important;
    }
    .container {
        max-width: 100% !important;
        min-width: 100% !important;
        /* padding: 0 !important; */
    }
    .cardd {
        width: 222px !important;
        margin: 0.5rem !important;
    }
    .page {
        padding: 0 !important;
    }
    .fa-bars {
        display: block !important;
    }
    .sidebar {
        display: flex !important;
    }
    .homesearch {
        width: 95%;
    }
    .cardcart {
        width: 100% !important;
        margin: 0.3 auto !important
    }
    .awardDes {
        flex-direction: column !important;
    }
    .eventImage,
    .desContent {
        width: 100% !important;
    }
    .desContent {
        padding: 1rem 0.5rem !important
    }
    .desContent h1 {
        font-size: 1.5rem !important;
    }
    .InputField {
        flex-direction: column !important;
    }
    .InputField div {
        width: 100% !important;
    }
    .nomineeState aside {
        width: 100% !important;
        margin: 0 0 0.5rem 0 !important;
    }
}

@media only screen and (max-width: 504px) {
    .cardd {
        width: 95% !important;
    }
}


/* LINK HOVERING CSS */

.link {
    position: relative;
    display: inline-block;
    text-decoration: none;
}

.link::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #006e9a;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
}

.link:hover::after {
    transform: scaleX(1);
}